import React, { useCallback, useContext } from 'react';

import {
  Button, Dropdown, Image, Text,
} from 'components';

import {
  disconnectWalletIcon,
  logoImage,
  logOutIcon,
  settingsIcon, threePointsIcon,
  userProfileIcon,
  walletIcon,
} from 'assets/images';
import { ButtonTheme, ModalContent, WalletStatus } from 'types';
import { ModalContext } from 'context';
import cx from 'classnames';
import { useScrollEffect } from 'hooks/layoutHooks';
import styles from 'containers/HeaderContainer/styles.module.scss';
import { useDispatch } from 'react-redux';
import { authLogout } from 'store/auth/actionCreators';
import { useShallowSelector, useUser } from 'hooks';
import { walletConnect, walletDisconnect } from 'store/wallet/actionCreators';
import { walletSelectors } from 'store/wallet/selectors';
import { shortenPhrase } from 'utils';

const headerGradientStartOffsetY = 46;

export const HeaderContainer = () => {
  const dispatch = useDispatch();

  const { isUserAuthenticated, email } = useUser();
  const {
    status,
    address,
    balance,
  } = useShallowSelector(walletSelectors.getState);

  const { openModal } = useContext(ModalContext);
  const { scrollY } = useScrollEffect();

  const isBackgroundGradient = scrollY > headerGradientStartOffsetY;

  const onLoginClick = useCallback(() => {
    openModal(ModalContent.LogInModal);
  }, [openModal]);

  const onLogOutClick = useCallback(() => {
    dispatch(authLogout());
  }, [dispatch]);

  const onWalletConnectClick = useCallback(() => {
    dispatch(walletConnect());
  }, [dispatch]);

  const onWalletDisconnectClick = useCallback(() => {
    dispatch(walletDisconnect());
  }, [dispatch]);

  return (
    <div
      className={cx(styles.header_container, {
        [styles.header_scrolled]: isBackgroundGradient,
      })}
    >
      <div className={styles.header_content}>
        <Image
          url={logoImage}
          className={styles.header_logo}
        />
        <div className={styles.header_buttons_container}>
          {!isUserAuthenticated && (
            <Button
              isSmall
              onClick={onLoginClick}
            >
              Log In
            </Button>
          )}

          {isUserAuthenticated && (
            <Dropdown
              buttonContent={(
                <div className={styles.user_dropdown}>
                  <div className={cx(styles.avatar, styles.no_avatar)} />
                  <Text
                    className={styles.user_dropdown_text}
                    type="p"
                  >
                    {email}
                  </Text>
                  <Image
                    className={styles.three_points}
                    url={threePointsIcon}
                  />
                </div>
              )}
            >
              <button className={styles.dropdown_item}>
                <Image
                  url={userProfileIcon}
                />
                <Text className={styles.dropdown_item_text}>User Profile</Text>
              </button>
              <button className={styles.dropdown_item}>
                <Image
                  url={settingsIcon}
                />
                <Text className={styles.dropdown_item_text}>Settings</Text>
              </button>
              <button
                onClick={onLogOutClick}
                className={styles.dropdown_item}
              >
                <Image
                  url={logOutIcon}
                />
                <Text className={cx(styles.dropdown_item_text, styles.red)}>Log Out</Text>
              </button>
            </Dropdown>
          )}

          {status !== WalletStatus.CONNECTED && (
            <Button
              onClick={onWalletConnectClick}
              isSmall
              theme={ButtonTheme.outline}
            >
              Connect Wallet
            </Button>
          )}

          {status === WalletStatus.CONNECTED && (
            <Dropdown
              buttonContent={(
                <div className={styles.wallet_address_wrapper}>
                  <Image
                    url={walletIcon}
                  />
                  <Text className={cx(styles.wallet_address)}>{shortenPhrase(address)}</Text>
                </div>
              )}
            >
              <Text className={cx(
                styles.connect_label,
                styles.grey,
              )}
              >
                Connected
                <p>
                  {balance}
                  &nbsp;8F
                </p>
              </Text>
              <button
                onClick={onWalletDisconnectClick}
                className={cx(styles.dropdown_item, styles.red)}
              >
                <Image
                  url={disconnectWalletIcon}
                />
                <Text className={cx(styles.dropdown_item_text, styles.red)}>Disconnect</Text>
              </button>
            </Dropdown>
          )}

        </div>
      </div>
    </div>
  );
};
