import { createReducer } from 'utils';
import { WalletState, WalletStatus } from './types';
import { walletHandlers } from './handlers';

export const metamaskInitialState: Readonly<WalletState> = {
  address: '',
  status: WalletStatus.INIT,
  balance: 0,
  network: null,
};

export default createReducer(metamaskInitialState, walletHandlers);
