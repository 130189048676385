import React, { FC } from 'react';
import { Image, Text } from 'components';
import { checkCircle, closeCircleIcon, exclamationCircleIcon } from 'assets/images';
import cx from 'classnames';
import styles from './styles.module.scss';

enum Types {
  success,
  info,
  error,
}
export type ToastifyProps = {
  type: keyof typeof Types,
  title?: string,
  message?: string,
};

const imageUrls: Record<keyof typeof Types, string> = {
  success: checkCircle,
  info: exclamationCircleIcon,
  error: closeCircleIcon,
};

export const Toastify: FC<ToastifyProps> = ({
  type,
  title,
  message,
}) => (
  <div className={styles.wrapper}>
    <div className={styles.title_wrapper}>
      <Image
        className={cx(styles.icon, styles[type])}
        url={imageUrls[type]}
      />
      <Text className={styles.title}>{title}</Text>
    </div>
    <Text className={styles.message}>{message}</Text>
  </div>
);
