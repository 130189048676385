import { ButtonIcon, Text } from 'components';
import cx from 'classnames';
import React, { FC } from 'react';
import { rightArrowBigIcon } from 'assets/images';
import styles from './styles.module.scss';

interface CardProps {
  className?: string;
  backgroundImage?: string;
}

export const Card: FC<CardProps> = ({ children, className, backgroundImage }) => (
  <section
    style={{ backgroundImage: `url(${backgroundImage})` }}
    className={cx(styles.card_outer_container, className)}
  >
    <div className={styles.card__content}>{children}</div>
    <div className={styles.line} />
    <div className={styles.card__read__container}>
      <Text
        className={styles.card__read__more}
        type="span"
      >
        Read
      </Text>
      <ButtonIcon imageURL={rightArrowBigIcon} />
    </div>
  </section>
);
