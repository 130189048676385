import { simpleSagaErrorHandler } from 'utils/api/simpleSagaErrorHandler';
import { put } from 'redux-saga/effects';
import { authSetTokens } from '../actionCreators';

export function* authLogoutSaga() {
  try {
    yield put(authSetTokens('', '', ''));
    document.cookie = '';
  } catch (e) {
    simpleSagaErrorHandler(e);
  }
}
