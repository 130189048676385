/* eslint-disable @typescript-eslint/no-explicit-any */
import { Action } from 'redux';
import { ActionFn } from 'types';

export const createReducer = <T> (
  initialState: T,
  handlers: Record<string, ActionFn<T, any>>,
  // eslint-disable-next-line @typescript-eslint/default-param-last,max-len
) => (state = initialState, action: Action<string> & any) => (Object.prototype.hasOwnProperty.call(handlers, action.type)
    ? handlers[action.type](state, action)
    : state);
