import React, {
  memo, useCallback, useContext, useState,
} from 'react';
import cx from 'classnames';
import {
  Button,
  CheckBox,
  ModalContentImageWrapper,
  Text,
  TextInput,
  VerificationCodeInput,
} from 'components';
import { ButtonTheme, ModalContent } from 'types';
import { ModalContext } from 'context';
import { useDispatch } from 'react-redux';
import { authEmail, authVerificationCode } from 'store/auth/actionCreators';
import { useAuth } from 'hooks';
import { NotificationName, showNotificationMessage } from 'appConstants';
import styles from './styles.module.scss';

const RegisterModalContainer = memo(() => {
  const dispatch = useDispatch();

  const { openModal, closeModal } = useContext(ModalContext);

  const {
    passwordConfirm,
    password,
    setPassword,
    setPasswordConfirm,
    email,
    setEmail,
    isEmailValidated,
    verificationCode,
    setVerificationCode,
  } = useAuth();

  const [isWaitingConfirmation, setIsWaitingConfirmation] = useState(false);

  const [isChecked, setIsChecked] = useState(false);

  const onLoginClick = useCallback(() => {
    openModal(ModalContent.LogInModal);
  }, [openModal]);

  const onCreateClick = useCallback(() => {
    if (!isEmailValidated) {
      showNotificationMessage(NotificationName.EnterCurrentEmail);
      return;
    }

    if (!password || !email || !passwordConfirm) {
      showNotificationMessage(NotificationName.CompleteFields);
      return;
    }

    if (password !== passwordConfirm) {
      showNotificationMessage(NotificationName.PasswordNotMatch);
      return;
    }

    if (!isWaitingConfirmation) {
      dispatch(authEmail(
        { email, password },
        () => setIsWaitingConfirmation(true),
      ));
    }

    if (isWaitingConfirmation) {
      if (!verificationCode || verificationCode.length < 6) {
        showNotificationMessage(NotificationName.FillVerificationCode);
        return;
      }

      dispatch(authVerificationCode(
        verificationCode,
        () => openModal(ModalContent.LogInModal),
      ));
    }
  }, [
    isWaitingConfirmation,
    dispatch,
    email,
    password,
    verificationCode,
    passwordConfirm,
    isEmailValidated,
    openModal,
  ]);

  return (
    <ModalContentImageWrapper>
      <div
        className={cx(styles.register_modal_container)}
      >
        <Text
          type="h3"
          className={styles.register_modal_title}
        >
          Create a 8.Finance account
        </Text>
        <TextInput
          placeholder="Email"
          value={email}
          onChangeValue={setEmail}
        />
        <TextInput
          placeholder="Password"
          value={password}
          onChangeValue={setPassword}
          isPassword
        />
        <TextInput
          placeholder="Confirm password"
          value={passwordConfirm}
          onChangeValue={setPasswordConfirm}
          isPassword
        />
        <CheckBox
          isChecked={isChecked}
          onToggle={setIsChecked}
          description="Subscribe to email updates"
        />
        {isWaitingConfirmation && (
          <VerificationCodeInput
            email={email}
            verificationCode={verificationCode}
            onVerificationCodeChange={setVerificationCode}
          />
        )}
        <div className={styles.register_modal_buttons_container}>
          <Button
            onClick={closeModal}
            theme={ButtonTheme.outline}
            className={styles.register_modal_button}
          >
            Cancel
          </Button>
          <Button
            className={styles.register_modal_button}
            onClick={onCreateClick}
          >
            Create
          </Button>
        </div>
        <div className={styles.register_modal_bottom_line_container}>
          <Text
            type="span"
            className={styles.register_modal_question_text}
          >
            Do not have an account yet?
          </Text>
          <Button
            onClick={onLoginClick}
            theme={ButtonTheme.text}
          >
            Log In
          </Button>
        </div>
      </div>
    </ModalContentImageWrapper>
  );
});

export { RegisterModalContainer };
