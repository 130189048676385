import React, { FC } from 'react';
import './InputVerificationCode.scss';
import { Text } from 'components/Text';
import ReactInputVerificationCode from 'react-input-verification-code';
import styles from './styles.module.scss';

type Props = {
  email: string
  verificationCode: string
  onVerificationCodeChange: (code: string) => void
};

export const VerificationCodeInput: FC<Props> = ({
  email,
  verificationCode,
  onVerificationCodeChange,

}) => (
  <div className={styles.verification_container}>
    <Text
      type="p"
      className={styles.verification_description}
    >
      {`Please enter the verification code that was sent to ${email}`}
    </Text>
    <div className="verification_input_styles">
      <ReactInputVerificationCode
        length={6}
        value={verificationCode}
        onChange={onVerificationCodeChange}
        placeholder=" "
      />
    </div>
  </div>
);
