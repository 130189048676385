import detectEthereumProvider from "@metamask/detect-provider";
import { networkChains } from "appConstants";
import { MetamaskRequestMethod } from "types";
import Web3 from "web3";
import {
  EightFinConnector,
  PrivateSaleConnector,
  Web3Wrapper,
} from "@eightfin/contract_connectors";
import abi from "./tokenabi.json";
// import { store } from '../index';
// import { select } from 'redux-saga/effects';
// import { selectAuthTokens } from '../store/auth/selectors';
// import { ContractSettings } from '../store/app/sagas';

export type Provider = any;

export const getAddressesConfig = () => {
  // вытащить store.state.auth.addresses
};

export const getAddress = async () => {
  const provider: Provider = await detectEthereumProvider();
  const addresses: string[] = await provider.request({
    method: MetamaskRequestMethod.eth_accounts,
  });
  return addresses;
};

export const getProvider = (): any => detectEthereumProvider();

export const changeNetwork = async (): Promise<any> => {
  const neededChain = networkChains.BSCMain;
  const provider: any = await detectEthereumProvider();
  try {
    await provider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: neededChain }],
    });
  } catch (err: any) {
    // This error code indicates that the chain has not been added to MetaMask
    if (err.code === 4902) {
      await provider.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainName: "BSCMain",
            chainId: neededChain,
            nativeCurrency: { name: "BSC", decimals: 18, symbol: "BSC" },
            rpcUrls: [
              "https://bsc-dataseed.binance.org/",
              "https://bsc-dataseed1.defibit.io/",
              "https://bsc-dataseed1.ninicoin.io/",
            ],
          },
        ],
      });
    }
  }
};

export const requestAccounts = async () => {
  const provider: Provider = await detectEthereumProvider();
  const addresses: string[] = await provider.request({
    method: MetamaskRequestMethod.eth_requestAccounts,
  });
  return addresses;
};

export const getMetamaskChainId = async (): Promise<string> => {
  const provider: Provider = await detectEthereumProvider();
  return provider.request({ method: MetamaskRequestMethod.eth_chainId });
};

export const getTokenBalance = async (): Promise<number> => {
  const provider: Provider = await detectEthereumProvider();
  try {
    const web3 = new Web3(provider);
    const tokenContract = new web3.eth.Contract(
      abi as any,
      "0xC8a1444412F6F3C3fb40C8da99d1015c7bA53e38"
    );
    const balance = await tokenContract.methods
      .balanceOf("0xc7607F9A0BDAA37d1CA2ed96d602cCBa3B830948")
      .call();

    return balance / 10 ** 18;
  } catch (e) {
    return 0;
  }
};

export const approve = async (spender: string, amount: number) => {
  const provider: Provider = await detectEthereumProvider();
  try {
    const web3 = new Web3(provider);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const web3Wrapper = new Web3Wrapper(web3);
    web3Wrapper.setAddress(provider.selectedAddress);
    const eightFinConnector = new EightFinConnector(web3Wrapper, {
      eightFin: "0x9ab52755180a1FdfAA86008ba53bA0239560e743",
      usdtFin: "0x9ab52755180a1FdfAA86008ba53bA0239560e743",
    });
    console.log("web3:", provider);
    return await eightFinConnector.approve(spender, amount);
  } catch (e) {
    console.log(e);
    return 0;
  }
};

export const buyToken = async (amount: number) => {
  const provider: Provider = await detectEthereumProvider();
  try {
    const web3 = new Web3(provider);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const web3Wrapper = new Web3Wrapper(web3);
    web3Wrapper.setAddress(provider.selectedAddress);
    const privateSaleConnector = new PrivateSaleConnector(web3Wrapper, {
      privateSale: "0xB2cdBEf9491DA51357d2Bf419B5a267F19dc381E",
    });
    console.log("web3:", provider);
    return await privateSaleConnector.buyToken(amount);
  } catch (e) {
    console.log(e);
    return 0;
  }
};

export const claimMyMoney = async () => {
  const provider: Provider = await detectEthereumProvider();
  try {
    const web3 = new Web3(provider);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const web3Wrapper = new Web3Wrapper(web3);
    web3Wrapper.setAddress(provider.selectedAddress);
    const privateSaleConnector = new PrivateSaleConnector(web3Wrapper, {
      privateSale: "0xB2cdBEf9491DA51357d2Bf419B5a267F19dc381E",
    });
    console.log("web3:", provider);
    return await privateSaleConnector.claimMyMoney();
  } catch (e) {
    console.log(e);
    return 0;
  }
};

export const calculateMyWithdrawAvailable = async () => {
  const provider: Provider = await detectEthereumProvider();
  try {
    const web3 = new Web3(provider);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const web3Wrapper = new Web3Wrapper(web3);
    web3Wrapper.setAddress(provider.selectedAddress);
    const privateSaleConnector = new PrivateSaleConnector(web3Wrapper, {
      privateSale: "0xB2cdBEf9491DA51357d2Bf419B5a267F19dc381E",
    });
    console.log("web3:", provider);
    return await privateSaleConnector.calculateMyWithdrawAvailable();
  } catch (e) {
    console.log(e);
    return 0;
  }
};

export const checkValidationNetwork = async (id?: string) => {
  let chainId: string | undefined = id;
  if (!chainId) {
    chainId = await getMetamaskChainId();
  }
  const networkIds = Object.values(networkChains);
  if (!chainId) return false;
  return networkIds.includes(chainId);
};

export const metamaskIsInstalled = async () => {
  const provider: Provider = await detectEthereumProvider();
  return !(!provider || !provider.isMetaMask);
};
