export enum ApiEndpoint {

  signInEmail = '/users/authorization/sign-in-by-email-pass',
  signUpEmail = '/users/authorization/sign-up-by-email-pass',

  AuthGoogle = '/auth/google/callback',
  GoogleLogin = '/auth/google-login',
  CreateWithEmail = '/users/create_with_email',
  EmailConfirm = '/users/confirm_create_with_code',
  Login = '/users/login',
  AuthRefresh = '/auth/refresh',

  PasswordResetRequest = '/users/password_reset_request',
  PasswordResetConfirm = '/users/password_reset_confirm',
  PasswordReset = '/users/password_reset',

  GetProfile = '/users/profile',
}
