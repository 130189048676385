import { Swiper } from 'swiper/react';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import cx from 'classnames';
import SwiperCore, {
  Navigation,
  Pagination,
  Autoplay,
} from 'swiper';
import { NavigationOptions } from 'swiper/types/modules/navigation';
import React, { FC, ReactNode } from 'react';
import { ButtonIcon } from 'components';
import { PaginationOptions } from 'swiper/types';
import { arrowLeftIcon, arrowRightIcon } from 'assets/images';
import styles from './styles.module.scss';

interface CarouselProps {
  spaceBetween?: number;
  slidesPerView?: number;
  children?: ReactNode;
}

const pagination: PaginationOptions = {
  clickable: true,
  dynamicBullets: true,
  bulletClass: styles.bullet,
  bulletActiveClass: styles.active,
  modifierClass: `${styles.pagination} `,
};

// const widthBullet = 180;

const categoryPrev = 'category-prev';
const categoryNext = 'category-next';
const navigation: NavigationOptions = {
  prevEl: `.${categoryPrev}`,
  nextEl: `.${categoryNext}`,
};

SwiperCore.use([Navigation, Pagination, Autoplay]);

export const CarouselSlider: FC<CarouselProps> = ({
  slidesPerView = 2, children, spaceBetween = 10,
}) => (
  <section className={styles.carousel_outer_container}>
    <Swiper
      slidesPerView={slidesPerView}
      autoplay={false}
      spaceBetween={spaceBetween}
      navigation={navigation}
      pagination={pagination}
      observeParents
      watchOverflow={false}
      observer
      resizeObserver
    >
      {children}
      <div
          // style={{
          //   maxWidth: `${(lengthList / slidesPerView) * widthBullet}px`,
          // }}
        className={styles.carousel__buttons}
      >
        <ButtonIcon
          imageURL={arrowLeftIcon}
          className={cx(styles.button__arrow, styles.left, categoryPrev)}
        />
        <ButtonIcon
          className={cx(styles.button__arrow, styles.right, categoryNext)}
          imageURL={arrowRightIcon}
        />
      </div>
    </Swiper>
  </section>
);
