import { all, fork } from 'redux-saga/effects';
import { profileSagas } from 'store/profile/sagas';
import { walletEffects } from './wallet/sagas';
import { authSagas } from './auth/sagas';
import { appSagas } from './app/sagas';

export default function* rootSaga() {
  yield all([
    fork(profileSagas),
    fork(authSagas),
    fork(appSagas),
    ...walletEffects,
  ]);
}
