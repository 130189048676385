import { ProfileState } from 'store/profile/types';
import { ActionFn } from 'store/types';
import { profileSetState } from 'store/profile/actionCreators';
import { ProfileActionType } from 'store/profile/actionTypes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ProfileHandlerFn<F extends (...args: any[]) => any> = ActionFn<ProfileState, ReturnType<F>>;

const setState: ProfileHandlerFn<typeof profileSetState> = (
  state,
  { payload },
) => ({
  ...state,
  ...payload,
});

export const profileHandlers = {
  [ProfileActionType.SetState]: setState,
};
