import { authPasswordResetConfirm } from 'store/auth/actionCreators';
import { call } from 'redux-saga/effects';
import { callApi } from 'utils/api';
import { ApiEndpoint } from 'utils/api/constants';
import { simpleSagaErrorHandler } from 'utils/api/simpleSagaErrorHandler';

export function* authPasswordResetConfirmSaga({
  payload,
  successCallback,
}: ReturnType<typeof authPasswordResetConfirm>) {
  try {
    yield call(callApi, {
      url: ApiEndpoint.PasswordResetConfirm,
      method: 'POST',
      data: {
        code: payload,
      },
    });
    if (successCallback) successCallback();
  } catch (e) {
    simpleSagaErrorHandler(e);
  }
}
