import React, { useContext } from 'react';
import { Modal } from 'components';
import { ModalContext } from 'context';
import {
  CreateAccountModal,
  LoginModalContainer,
  RegisterModalContainer,
  ResetPasswordModal,
} from 'containers';

import { ModalContent } from './types';

export const ModalLayout: React.FC = () => {
  const {
    isModalOpen,
    currentModal,
    closeModal,
  } = useContext(ModalContext);

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={closeModal}
    >
      { currentModal === ModalContent.LogInModal && <LoginModalContainer /> }
      { currentModal === ModalContent.RegisterModal && <RegisterModalContainer /> }
      { currentModal === ModalContent.CreateAccount && <CreateAccountModal /> }
      { currentModal === ModalContent.ResetPassword && <ResetPasswordModal /> }
    </Modal>
  );
};
