import React from 'react';
import { Route, Routes as RoutesDom } from 'react-router-dom';
import { MainLayout } from 'layouts';
import { Home } from 'pages';
import { routes } from 'appConstants';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/scss/main.scss';
import { Token } from '../../pages/Token';
import { ReceiveTokens } from '../../pages/ReceiveTokens';

export const MainRouter = () => (
  <MainLayout>
    <RoutesDom>
      <Route
        path="/"
        element={<Home />}
      />
      <Route
        path={routes.home.root}
        element={<Home />}
      />
      <Route
        path={routes.token.root}
        element={<Token />}
      />
      <Route
        path="/receiveTokens"
        element={<ReceiveTokens />}
      />

    </RoutesDom>
    <ToastContainer
      hideProgressBar
      position="top-center"
    />
  </MainLayout>
);
