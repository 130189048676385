import { WalletState, ActionFn } from 'types';
import { WalletActionType } from './actionTypes';
import { walletDisconnect, walletSetState } from './actionCreators';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type WalletStateActionFn<F extends (...args: any) => any> =
ActionFn<WalletState, ReturnType<F>>;

const setState: WalletStateActionFn<typeof walletSetState> = (
  state,
  { payload },
) => ({
  ...state,
  ...payload,
});

const disconnect: WalletStateActionFn<typeof walletDisconnect> = (
  state,
) => ({
  ...state,
  address: '',
});

export const walletHandlers = {
  [WalletActionType.SET_STATE]: setState,
  [WalletActionType.DISCONNECT]: disconnect,
};
