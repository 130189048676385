export const routes = {
  home: {
    root: '/home',
    title: 'Home',
  },
  token: {
    root: '/token',
    title: 'Token',
  },
};
