import { call, put } from 'redux-saga/effects';
import { callApi } from 'utils/api';
import { ApiEndpoint } from 'utils/api/constants';
import { simpleSagaErrorHandler } from 'utils/api/simpleSagaErrorHandler';
import { authGoogle, authSetState } from 'store/auth/actionCreators';
import { UserAuthDTO } from 'store/auth/types';
import { profileGetProfile } from 'store/profile/actionCreators';

export function* authGoogleSaga({
  payload,
  successCallback,
}: ReturnType<typeof authGoogle>) {
  try {
    const {
      name,
      access_token,
      refresh_token,
    }: UserAuthDTO = yield call(callApi, {
      method: 'POST',
      url: ApiEndpoint.GoogleLogin,
      data: {
        token: payload,
      },
    });

    yield put(authSetState({
      tokens: {
        access: access_token,
        refresh: refresh_token,
      },
      name,
    }));
    if (successCallback) successCallback();
    yield put(profileGetProfile());
  } catch (e) {
    simpleSagaErrorHandler(e);
  }
}
