export enum AuthActionType {
  Ready = 'AUTH.READY',
  SetTokens = 'AUTH.SET_TOKENS',
  SetContractAddresses = 'AUTH.SET_CONTRACT_ADDRESSES',
  SetState = 'AUTH.SET_STATE',
  Logout = 'AUTH.LOGOUT',
  Refresh = 'AUTH.REFRESH',

  LoginEmail = 'AUTH.LOGIN_EMAIL',
  LoginMetamask = 'AUTH.LOGIN_METAMASK',
  LoginGoogle = 'AUTH.LOGIN_GOOGLE',

  SignUpEmail = 'AUTH.SIGN_UP_EMAIL',

  RequestPasswordRecovery = 'AUTH.REQUEST_PASSWORD_RECOVERY',

  GOOGLE_AUTH = 'AUTH.GOOGLE_AUTH',
  EMAIL_AUTH = 'AUTH.EMAIL_AUTH',
  VERIFICATION_CODE = 'AUTH.VERIFICATION_CODE',
  LOGIN = 'AUTH.LOGIN',
  LOGOUT = 'AUTH.LOGOUT',

  PASSWORD_RESET_REQUEST = 'AUTH.PASSWORD_RESET_REQUEST',
  PASSWORD_RESET_CONFIRM = 'AUTH.PASSWORD_RESET_CONFIRM',
  PASSWORD_RESET = 'AUTH.PASSWORD_RESET',
}
