export const contacts = [
  {
    label: 'Telegram',
    link: '',
  },
  {
    label: 'Twitter',
    link: '',
  },
  {
    label: 'Discord',
    link: '',
  },
];
