import React, {
  FC, useCallback, useContext, useState,
} from 'react';
import {
  Button, Image, Text, TextInput, VerificationCodeInput,
} from 'components';
import { logoImage } from 'assets/images';
import { ButtonTheme } from 'components/Button/types';
import { ModalContext } from 'context';
import { useDispatch } from 'react-redux';
import {
  authPasswordReset,
  authPasswordResetConfirm,
  authPasswordResetRequest,
} from 'store/auth/actionCreators';
import { NotificationName, showNotificationMessage } from 'appConstants';
import { useAuth } from 'hooks';
import styles from './styles.module.scss';

export const ResetPasswordModal: FC = () => {
  const dispatch = useDispatch();
  const { closeModal } = useContext(ModalContext);

  const {
    email,
    setEmail,
    setPassword,
    setPasswordConfirm,
    setVerificationCode,
    password,
    passwordConfirm,
    verificationCode,
    isEmailValidated,
  } = useAuth();

  const [isWaitingConfirmation, setIsWaitingConfirmation] = useState(false);
  const [verificationCodeConfirmed, setVerificationCodeConfirmed] = useState(false);

  const onConfirmClick = useCallback(() => {
    if (!isEmailValidated) {
      showNotificationMessage(NotificationName.EnterCurrentEmail);
      return;
    }

    if (!isWaitingConfirmation) {
      dispatch(authPasswordResetRequest(
        email,
        () => setIsWaitingConfirmation(true),
      ));
      return;
    }

    if (!verificationCodeConfirmed) {
      dispatch(authPasswordResetConfirm(
        verificationCode,
        () => setVerificationCodeConfirmed(true),
      ));
      return;
    }

    if (password !== passwordConfirm) {
      showNotificationMessage(NotificationName.PasswordNotMatch);
      return;
    }

    dispatch(authPasswordReset(password, closeModal));
  }, [
    isEmailValidated,
    closeModal,
    dispatch,
    email,
    passwordConfirm,
    password,
    verificationCode,
    isWaitingConfirmation,
    verificationCodeConfirmed,
  ]);

  return (
    <div className={styles.wrapper}>
      <Image
        url={logoImage}
        className={styles.logo}
      />
      <Text className={styles.title}>
        Reset password
      </Text>
      <Text className={styles.sub_title}>Enter email address which your account is linked to</Text>
      <TextInput
        onChangeValue={setEmail}
        classNameContainer={styles.input}
        placeholder="Email"
        value={email}
      />
      {isWaitingConfirmation && !verificationCodeConfirmed && (
        <VerificationCodeInput
          email={email}
          verificationCode={verificationCode}
          onVerificationCodeChange={setVerificationCode}
        />
      )}
      {verificationCodeConfirmed && (
        <div className={styles.passwords}>
          <TextInput
            classNameContainer={styles.password}
            placeholder="Password"
            value={password}
            onChangeValue={setPassword}
            isPassword
          />
          <TextInput
            classNameContainer={styles.password}
            placeholder="Confirm password"
            value={passwordConfirm}
            onChangeValue={setPasswordConfirm}
            isPassword
          />
        </div>
      )}
      <div className={styles.buttons}>
        <Button
          onClick={closeModal}
          theme={ButtonTheme.outline}
          className={styles.button}
        >
          Cancel
        </Button>
        <Button
          onClick={onConfirmClick}
          className={styles.button}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};
