import { takeLatest } from "redux-saga/effects";
import { AppActionType } from "store/app/actionTypes";
import { onAppMountSaga } from "./onAppMountSaga";

export type ContractSettings = {
  EIGHT_FIN_TOKEN: string;
  USDT_FIN_TOKEN: string;
  PRIVATE_SALE_TOKEN: string;
};

export function* appSagas() {
  yield takeLatest(AppActionType.OnMount, onAppMountSaga);
}
