/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { setNotification } from '../setNotification';

type ErrorWithMessage = {
  message: string
};

function instanceOfErrorWithMessage(object: any): object is ErrorWithMessage {
  return 'message' in object;
}

export function simpleSagaErrorHandler(exception: unknown) {
  let message = '';
  if (axios.isAxiosError(exception)) {
    message = exception.response?.data.message || exception.message;
  }

  if (instanceOfErrorWithMessage(exception)) {
    message = exception.message;
  }

  if (typeof exception === 'string') {
    message = exception;
  }

  if (message.length === 0) {
    message = 'Error';
  }

  setNotification({ title: message, type: 'error' });
}
