import React, {
  FC,
} from 'react';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  buttonContent: React.ReactNode
};

export const Dropdown: FC<Props> = ({
  buttonContent,
  children,
}) => {
  const { buttonProps, isOpen } = useDropdownMenu(3);

  // const items = useMemo(() => (Array.isArray(children) ? children : [children]), [children]);

  return (
    <div className={styles.wrapper}>
      <button {...buttonProps}>{buttonContent}</button>
      <div
        className={cx(styles.menu, { [styles.visible]: isOpen })}
        role="menu"
      >
        {children}
      </div>
    </div>
  );
};
