import { simpleSagaErrorHandler } from 'utils/api/simpleSagaErrorHandler';
import { call, put } from 'redux-saga/effects';
import { callApi } from 'utils/api';
import { ApiEndpoint } from 'utils/api/constants';
import { profileGetProfile } from 'store/profile/actionCreators';
import {
  authLogin, authLogout, authSetState,
} from '../actionCreators';
import { UserAuthDTO } from '../types';

export function* authLoginSaga({
  successCallback,
  payload: {
    remember,
    email,
    password,
  },
}: ReturnType<typeof authLogin>) {
  try {
    const {
      access_token,
      refresh_token,
      name,
    }: UserAuthDTO = yield call(callApi, {
      method: 'POST',
      url: ApiEndpoint.Login,
      data: {
        email,
        password,
        remember,
      },
    });

    yield put(authSetState({
      name,
      tokens: {
        access: access_token,
        refresh: refresh_token,
      },
    }));
    yield put(profileGetProfile());
    if (successCallback) successCallback();
  } catch (e) {
    yield put(authLogout());
    simpleSagaErrorHandler(e);
  }
}
