import React, { FC, ReactNode } from 'react';
import cx from 'classnames';
import { Footer } from 'components';
import { ModalLayout } from 'layouts';
import { HeaderContainer } from 'containers';
import styles from './styles.module.scss';

type MainLayoutProps = { children: ReactNode };

const MainLayout: FC<MainLayoutProps> = ({ children }) => (
  <div className={cx(styles.main_layout__container)}>
    <HeaderContainer />
    <main className={cx(styles.mainContainer)}>
      {children}
      <Footer />
    </main>
    <ModalLayout />
  </div>
);

export { MainLayout };
