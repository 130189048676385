import { ReduxState, AuthState } from 'types';

export const selectAuthTokens = (state: ReduxState) => state.auth.tokens;
export const selectContractConfig = (state: ReduxState) => state.auth;

export const authSelectors = {
  getState: (state: ReduxState) => state.auth,
  getProp: <PropKey extends keyof AuthState>(propKey: PropKey) => (
    state: ReduxState,
  ) => state.auth[propKey],
};
