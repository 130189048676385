import { simpleSagaErrorHandler } from 'utils/api/simpleSagaErrorHandler';
import { call, put } from 'redux-saga/effects';
import { callApi } from 'utils/api';
import { ApiEndpoint } from 'utils/api/constants';
import { ProfileDTO } from 'store/profile/types';
import { profileSetState } from 'store/profile/actionCreators';

export function* profileGetProfileSaga() {
  try {
    const {
      emailAccount,
      name,
      surname,
      gmailAccount,
    }: ProfileDTO = yield call(callApi, {
      method: 'GET',
      url: ApiEndpoint.GetProfile,
    });

    yield put(profileSetState({
      name,
      surname,
      email: emailAccount || gmailAccount,
    }));
  } catch (e) {
    simpleSagaErrorHandler(e);
  }
}
