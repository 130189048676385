import { Text } from 'components';
import React, { FC } from 'react';
import styles from './styles.module.scss';

export const WelcomContainer: FC = () => (
  <div className={styles.welcom__container}>
    <Text
      className={styles.welcom}
      type="h2"
    >
      Welcome to
    </Text>
    <Text
      className={styles.welcom__head}
      type="h1"
    >
      8.Finance
    </Text>
    <Text
      className={styles.welcom__description}
      type="h2"
    >
      MetaFi made easy
    </Text>
  </div>
);
