export const pointsText = {
  register:
    'Do not have an account yet? Register and become a member of 8.Finance community!',
  connectWallet:
    'Connect your MetaMask wallet to recieve the reward when it is available.',
  linkTelegram:
    'Link your Telegram account to instantly get all the news about our project!',
  connectTelegram:
    'Link your Telegram account to instantly get all the news about our project!',
};
