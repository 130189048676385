import React, {
  memo, useCallback, useContext, useState,
} from 'react';
import cx from 'classnames';
import {
  Button,
  CheckBox,
  ModalContentImageWrapper,
  Text,
  TextInput,
} from 'components';
import { ButtonTheme, ModalContent } from 'types';
import { ModalContext } from 'context';
import { useDispatch } from 'react-redux';
import { authLogin } from 'store/auth/actionCreators';
import { useAuth } from 'hooks';
import { NotificationName, showNotificationMessage } from 'appConstants';
import { GoogleLoginButtonContainer } from 'containers/GoogleLoginButtonContainer';
import styles from './styles.module.scss';

const LoginModalContainer = memo(() => {
  const dispatch = useDispatch();
  const { openModal, closeModal } = useContext(ModalContext);

  const {
    password, email, setEmail, setPassword, isEmailValidated,
  } = useAuth();

  const [isChecked, setIsChecked] = useState(false);

  const onRegisterClick = useCallback(() => {
    openModal(ModalContent.RegisterModal);
  }, [openModal]);

  const onLogInClick = useCallback(() => {
    if (!isEmailValidated) {
      showNotificationMessage(NotificationName.EnterCurrentEmail);
      return;
    }

    if (!email || !password) {
      showNotificationMessage(NotificationName.CompleteFields);
      return;
    }

    dispatch(authLogin({ email, password, remember: isChecked }, closeModal));
  }, [dispatch, email, password, isChecked, isEmailValidated, closeModal]);

  return (
    <ModalContentImageWrapper>
      <div
        className={cx(styles.login_modal_container)}
      >
        <Text
          type="h3"
          className={styles.login_modal_title}
        >
          Log In
        </Text>
        <TextInput
          placeholder="Email"
          value={email}
          onChangeValue={setEmail}
        />
        <TextInput
          placeholder="Password"
          value={password}
          onChangeValue={setPassword}
          isPassword
        />
        <CheckBox
          isChecked={isChecked}
          onToggle={setIsChecked}
          description="Remember me"
        />
        <div className={styles.login_modal_buttons_container}>
          <Button
            onClick={closeModal}
            theme={ButtonTheme.outline}
            className={styles.login_modal_button}
          >
            Cancel
          </Button>
          <Button
            onClick={onLogInClick}
            className={styles.login_modal_button}
          >
            Log In
          </Button>
        </div>
        <GoogleLoginButtonContainer onLogin={closeModal}>
          Log In with Google account
        </GoogleLoginButtonContainer>
        <div className={styles.login_modal_bottom_line_container}>
          <Text
            type="span"
            className={styles.login_modal_question_text}
          >
            Do not have an account yet?
          </Text>
          <Button
            onClick={onRegisterClick}
            theme={ButtonTheme.text}
          >
            Register
          </Button>
        </div>
      </div>
    </ModalContentImageWrapper>
  );
});

export { LoginModalContainer };
