import React, { useEffect } from 'react';
import { ModalProvider } from 'context';
import { useDispatch } from 'react-redux';
import { appOnMount } from 'store/app/actionCreators';
import { MainRouter } from '../MainRouter';

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appOnMount());
  }, [dispatch]);

  return (
    <ModalProvider>
      <MainRouter />
    </ModalProvider>
  );
};

export { App };
