export enum Network {
  BSCMain = "BSCMain",
  BSCTest = "BSCTest",
}

export const networkChains: Record<Network, string> = {
  [Network.BSCMain]: "0x38",
  [Network.BSCTest]: "0x61",
};

type Chain = {
  chainId: string;
  chainName: string;
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: number;
  };
  rpcUrls: string[];
  blockExplorerUrls: string[];
};

type Chains = Pick<Record<Network, Chain>, Network.BSCMain | Network.BSCTest>;

export const chains: Chains = {
  [Network.BSCTest]: {
    chainId: networkChains.BSCTest,
    chainName: "BSC Testnet",
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: ["https://data-seed-prebsc-2-s2.binance.org:8545"],
    blockExplorerUrls: ["https://explorer.binance.org/smart-testnet"],
  },
  [Network.BSCMain]: {
    chainId: networkChains.BSCMain,
    chainName: "Binance Smart Chain",
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: ["https://bsc-dataseed.binance.org/"],
    blockExplorerUrls: ["https://bscscan.com"],
  },
};

export const BSC_NETWORKS = [networkChains.BSCMain, networkChains.BSCTest];
