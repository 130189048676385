import React, { memo, useCallback } from 'react';
import cx from 'classnames';
import {
  checkboxDisabledIcon,
  checkboxCheckedIcon,
  checkboxEmptyIcon,
} from 'assets/images';
import { ButtonIcon, Text } from 'components';
import styles from './styles.module.scss';

type CheckBoxProps = {
  isChecked: boolean;
  description?: string;
  isDisabled?: boolean;
  className?: string;
  iconClassName?: string;
  descriptionClassName?: string;
  onToggle: (value: boolean) => void;
};

export const CheckBox: React.FC<CheckBoxProps> = memo(({
  isChecked,
  description,
  isDisabled = false,
  className,
  iconClassName,
  descriptionClassName,
  onToggle,
}) => {
  let icon = isChecked ? checkboxCheckedIcon : checkboxEmptyIcon;
  if (isDisabled) {
    icon = checkboxDisabledIcon;
  }
  const onCheckBoxClick = useCallback(() => {
    if (!isDisabled) {
      onToggle(!isChecked);
    }
  }, [isChecked, isDisabled, onToggle]);

  return (
    <div className={cx(styles.check_box_container, className)}>
      <ButtonIcon
        imageURL={icon}
        onClick={onCheckBoxClick}
        className={iconClassName}
      />
      {description !== undefined && (
      <Text
        type="span"
        className={cx(
          styles.check_box_description,
          { [styles.disabled]: isDisabled },
          descriptionClassName,
        )}
      >
        {description}
      </Text>
      )}
    </div>
  );
});
