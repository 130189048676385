import { createReducer } from "utils";
import { AuthState } from "types";
import { authHandlers } from "./handlers";

export const authInitialState: Readonly<AuthState> = {
  tokens: {
    access: "",
    refresh: "",
  },
  addresses: {
    EIGHT_FIN_TOKEN: "",
    USDT_FIN_TOKEN: "",
    PRIVATE_SALE_TOKEN: "",
  },

  isLoginEmailLoading: false,
  isSignUpEmailLoading: false,
};

export default createReducer(authInitialState, authHandlers);
