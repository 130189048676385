import { connectRouter } from 'connected-react-router';
import { history } from 'utils';
import wallet from 'store/wallet/reducer';
import auth from './auth/reducer';
import profile from './profile/reducer';

export default {
  wallet,
  router: connectRouter(history),
  auth,
  profile,
};
