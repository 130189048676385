import React, { memo } from "react";
import { Text } from "../../components/Text";
import styles from "../../containers/modals/LoginModalContainer/styles.module.scss";
import { TextInput } from "../../components/TextInput";
import { Button } from "../../components/Button";
import {
  approve,
  buyToken,
  calculateMyWithdrawAvailable,
  claimMyMoney,
  getAddressesConfig,
} from "../../utils";
import { store } from "../../index";

export const Token = memo(() => {
  const config = getAddressesConfig();
  console.log({ config });
  const [amount, setAmount] = React.useState("0");
  const { wallet } = store.getState();
  const { address } = wallet;
  const onBuyClick = async () => {
    if (address.split("").length < 3) {
      alert("Вы не подключили кошелек");
    }
    try {
      const resultApprove = await approve(
        "0xB2cdBEf9491DA51357d2Bf419B5a267F19dc381E",
        Number(amount)
      );
      console.log("resultApprove:", resultApprove);
      const resultBuy = await buyToken(Number(amount));
      console.log("resultBuy:", resultBuy);
      alert("successfuly bought");
    } catch (e) {
      console.log(e);
    }

    console.log("Pressed onBuyClick:", amount, address);
  };
  const onClaimMyMoneyClick = async () => {
    const result = await claimMyMoney();
    console.log("Pressed onClaimMyMoneyClick:", result);
  };
  const onCalculateClick = async () => {
    const result = await calculateMyWithdrawAvailable();
    console.log("Pressed onCalculateClick:", result);
  };
  return (
    <>
      <Text type="h3">Buy Token</Text>
      <TextInput
        placeholder="Amount"
        value={amount}
        onChangeValue={setAmount}
      />
      <Button
        onClick={onBuyClick}
        className={styles.login_modal_button}
      >
        Buy
      </Button>
      <Button
        onClick={onClaimMyMoneyClick}
        className={styles.login_modal_button}
      >
        ClaimMyMoney
      </Button>
      <Button
        onClick={onCalculateClick}
        className={styles.login_modal_button}
      >
        CalculateMyWithdrawAvailable
      </Button>
    </>
  );
});
