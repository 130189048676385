/* eslint-disable react/jsx-props-no-spreading */
import React, {
  ReactNode,
} from 'react';

import { Image } from 'components';

import { logoImage } from 'assets/images';
import styles from './styles.module.scss';

type ModalContentImageWrapperType = {
  children: ReactNode
};

const ModalContentImageWrapper = ({
  children,
}: ModalContentImageWrapperType) => (
  <div className={styles.modal_content_wrapper_container}>
    <div className={styles.modal_content_wrapper_left_side} />
    <div className={styles.modal_content_wrapper_right_side}>
      <Image
        url={logoImage}
        className={styles.modal_content_wrapper_logo}
      />
      {children}
    </div>
  </div>
);

export { ModalContentImageWrapper };
