import React, { memo } from 'react';
import cx from 'classnames';
import { Text } from 'components';
import styles from './styles.module.scss';

type LinkItemProps = {
  label: string;
  url: string;
  classNameContainer?: string;
  classNameLabel?: string;
};

const LinkItem = memo<LinkItemProps>((
  {
    label,
    url,
    classNameContainer,
    classNameLabel,
  },
) => (
  <div className={cx(styles.label_container, classNameContainer)}>
    <a href={url}>
      <Text
        type="span"
        className={cx(styles.contact_item_label, classNameLabel)}
      >
        {label}
      </Text>
    </a>
  </div>
));

export { LinkItem };
