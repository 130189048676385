import React, {
  ChangeEvent,
  memo,
  useCallback,
  useMemo,
  useState,
} from 'react';
import cx from 'classnames';
import { useSetFocus } from 'hooks';
import { ButtonIcon } from 'components';
import { eyeCrossedIcon, eyeIcon } from 'assets/images';
import styles from './styles.module.scss';

type TextInputProps = {
  value: string;
  defaultValue?: string;
  name?: string;
  isPassword?: boolean;
  label?: string;
  classNameInput?: string;
  classNameLabel?: string;
  classNameContainer?: string;
  disabled?: boolean;
  isWithClear?: boolean;
  onChangeValue?: (text: string) => void;
  placeholder?: string;
  isTextOnly?: boolean;
};

export const TextInput = memo<TextInputProps>(({
  value,
  defaultValue,
  name,
  isPassword,
  label,
  classNameInput,
  classNameLabel,
  classNameContainer,
  disabled = false,
  onChangeValue,
  placeholder = '',
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const { ref, setFocus } = useSetFocus();

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (onChangeValue !== undefined) {
      onChangeValue(e.target.value);
    }
  }, [onChangeValue]);

  const inputType = useMemo(() => (
    isPassword && !isPasswordVisible ? 'password' : 'text'
  ), [isPassword, isPasswordVisible]);

  const onPasswordToggleClick = useCallback(() => {
    setIsPasswordVisible(!isPasswordVisible);
  }, [isPasswordVisible]);

  return (
    <div className={cx(styles.input__container, classNameContainer)}>
      {label && (
        <div
          className={cx(styles.input__label, classNameLabel)}
          onClick={setFocus}
          onKeyPress={undefined}
          role="button"
          tabIndex={0}
        >
          {label}
        </div>
      )}
      <div className={cx(styles.input__box, { [styles.input__withIcon]: isPassword })}>
        <input
          ref={ref}
          name={name}
          value={value}
          type={inputType}
          className={classNameInput}
          disabled={disabled}
          defaultValue={defaultValue}
          onChange={handleChange}
          placeholder={placeholder}
        />
        {isPassword && (
        <ButtonIcon
          imageURL={isPasswordVisible ? eyeIcon : eyeCrossedIcon}
          className={cx(styles.input__icon)}
          onClick={onPasswordToggleClick}
        />
        )}
      </div>
    </div>
  );
});
