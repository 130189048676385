import { call, put, select } from 'redux-saga/effects';
import { walletSelectors } from 'store/wallet/selectors';
import { walletConnect } from 'store/wallet/actionCreators';
import { AuthState } from 'store/auth/types';
import { selectAuthTokens } from 'store/auth/selectors';
import { profileSelectors } from 'store/profile/selectors';
import { profileGetProfile } from 'store/profile/actionCreators';
import { simpleSagaErrorHandler } from 'utils/api/simpleSagaErrorHandler';
import { callApi } from '../../../utils/api';
import { ContractSettings } from './index';
import { setContractAddresses } from '../../auth/actionCreators';

export function* onAppMountSaga() {
  const data: ContractSettings = yield call(callApi, {
    method: 'GET',
    url: '/config/addresses',
  });
  yield put(setContractAddresses(data));
  const address: string = yield select(walletSelectors.getProp('address'));

  if (address.length > 0) {
    yield put(walletConnect());
  }

  try {
    const { access }: AuthState['tokens'] = yield select(selectAuthTokens);
    const email: string = yield select(profileSelectors.getProp('email'));

    if (access.length > 0 && !email) {
      yield put(profileGetProfile());
    }
  } catch (e) {
    simpleSagaErrorHandler(e);
  }
}
