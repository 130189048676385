import { call } from 'redux-saga/effects';
import {
  authPasswordReset,
} from 'store/auth/actionCreators';
import { simpleSagaErrorHandler } from 'utils/api/simpleSagaErrorHandler';
import { callApi } from 'utils/api';
import { ApiEndpoint } from 'utils/api/constants';

export function* authResetPasswordSaga({
  payload,
  successCallback,
}: ReturnType<typeof authPasswordReset>) {
  try {
    yield call(callApi, {
      url: ApiEndpoint.PasswordReset,
      method: 'POST',
      data: {
        password: payload,
      },
    });
    if (successCallback) successCallback();
  } catch (e) {
    simpleSagaErrorHandler(e);
  }
}
