import React, { memo } from 'react';

import { Image, LinkItem, Text } from 'components';
import { contacts } from 'appConstants';
import { logoGreyImage } from 'assets/images';
import styles from './styles.module.scss';

const Footer = memo(() => (
  <div
    className={styles.footer_container}
  >
    <div className={styles.footer_left}>
      <div className={styles.footer_links_line_first}>
        <LinkItem
          url=""
          label="Terms of Use"
          classNameContainer={styles.footer_link_margin_right}
        />
        <LinkItem
          url=""
          label="Privacy policy"
        />
      </div>
      <div className={styles.footer_links_line_second}>
        {contacts.map(({ label, link }) => (
          <LinkItem
            key={label}
            url={link}
            label={label}
            classNameContainer={styles.footer_links_line_second_margin}
          />
        ))}
      </div>
    </div>
    <Image
      url={logoGreyImage}
    />
    <div className={styles.footer_right}>
      <Text
        type="p"
        className={styles.footer_copyright_text}
      >
        © Copyright 2020-2021. All Rights Reserved
      </Text>
    </div>
  </div>
));

export { Footer };
