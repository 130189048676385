import { useShallowSelector } from 'hooks/useShallowSelector';
import { selectAuthTokens } from 'store/auth/selectors';
import { profileSelectors } from 'store/profile/selectors';

export const useUser = () => {
  const tokens = useShallowSelector(selectAuthTokens);
  const email = useShallowSelector(profileSelectors.getProp('email'));

  return {
    isUserAuthenticated: tokens.access.length > 0,
    email,
  };
};
