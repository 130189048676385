import { ProfileState } from 'store/profile/types';
import { ProfileActionType } from 'store/profile/actionTypes';

export const profileSetState = (payload: Partial<ProfileState>) => ({
  type: ProfileActionType.SetState,
  payload,
});

export const profileGetProfile = () => ({
  type: ProfileActionType.getProfile,
});
