import web3 from 'web3';
import React, { useEffect, useState } from 'react';
import { store } from '../../index';
import { adminApi } from '../../utils/api';
import { Button } from '../../components';
import styles from '../../containers/modals/LoginModalContainer/styles.module.scss';

export const ReceiveTokens = () => {
  const { wallet } = store.getState();
  const [requests, setRequests] = useState([]);
  const [isWalletConnected, toggleWallet] = useState(true);
  const [canReceiveTokens, setReceiveTokens] = useState(true);
  useEffect(() => {
    async function getRequsts() {
      if (wallet.address) {
        wallet.address = web3.utils.toChecksumAddress(wallet.address);
        const { data } = await adminApi.get(`/request/${wallet.address}`);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        if (data.length === 0) { // @ts-ignore
          setReceiveTokens(false);
        }
        setRequests(data);
      } else toggleWallet(false);
    }

    getRequsts();
  }, []);
  const onReceiveClick = async () => {
    if (!wallet.address) return;
    const response = await adminApi.post(`token/receiveTokens/${wallet.address}`);
    if (response.status >= 400) {
      alert('Error occured try again or contact the support');
      return;
    }
    if (response.data[0].status && response.data[0].status === 'error') {
      let errorMessage = '';
      for (let i = 0; i < response.data.length; i++) {
        errorMessage += response.data[i].reason;
      }
      alert(errorMessage);
      return;
    }
    alert('Token are sent :)');
  };
  return (
    <div style={{ color: 'white' }}>
      {isWalletConnected ? (<h1>Withdrawal tokens</h1>) : (<h3>Connect wallet</h3>)}
      {
                requests.map((el: any) => (
                  canReceiveTokens
                    ? (
                      <h3>
                        Token name :
                        {el.tokenAddress.tokenName}
                        , amount:
                        {el.amount}
                      </h3>
                    ) : ''
                ))
            }
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      {canReceiveTokens ? (<div />) : (<h3>You don't have tokens to receive</h3>)}
      {isWalletConnected && canReceiveTokens ? (
        <Button
          onClick={onReceiveClick}
          className={styles.login_modal_button}
        >
          Receive Tokens on your wallet
        </Button>
      ) : null}
    </div>
  );
};
