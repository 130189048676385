import { takeLatest } from 'redux-saga/effects';
import { authPasswordResetRequestSaga } from './authPasswordResetRequestSaga';
import { authPasswordResetConfirmSaga } from './authPasswordResetConfirmSaga';
import { authResetPasswordSaga } from './authResetPasswordSaga';
import { authGoogleSaga } from './authGoogleSaga';
import { authEmailSaga } from './authEmailSaga';
import { AuthActionType } from '../actionTypes';
import { authVerificationCodeSaga } from './authVerificationCodeSaga';
import { authLoginSaga } from './authLoginSaga';
import { authLogoutSaga } from './authLogoutSaga';

export function* authSagas() {
  yield takeLatest(AuthActionType.GOOGLE_AUTH, authGoogleSaga);
  yield takeLatest(AuthActionType.EMAIL_AUTH, authEmailSaga);
  yield takeLatest(AuthActionType.VERIFICATION_CODE, authVerificationCodeSaga);
  yield takeLatest(AuthActionType.LOGIN, authLoginSaga);
  yield takeLatest(AuthActionType.LOGOUT, authLogoutSaga);
  yield takeLatest(AuthActionType.PASSWORD_RESET_REQUEST, authPasswordResetRequestSaga);
  yield takeLatest(AuthActionType.PASSWORD_RESET_CONFIRM, authPasswordResetConfirmSaga);
  yield takeLatest(AuthActionType.PASSWORD_RESET, authResetPasswordSaga);
}
