import './initialImports';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import configureStore from 'store/configureStore';
import 'assets/index.scss';
import { BrowserRouter } from 'react-router-dom';
import { App } from 'containers';

const config = configureStore();
export const { store, persistor } = config;

ReactDOM.render(
  <Provider store={store}>
    <PersistGate
      loading={null}
      persistor={persistor}
    >
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);
