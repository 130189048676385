import React, {
  createContext,
  useMemo,
  useState,
} from 'react';
import { ModalContent } from 'types';

type ModalContextProps = {
  isModalOpen: boolean;
  currentModal: ModalContent | undefined;
  closeModal: () => void;
  openModal: (modal: ModalContent) => void;
};

export const ModalContext = createContext<ModalContextProps>({
  isModalOpen: false,
  currentModal: undefined,
  closeModal: () => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  openModal: (modal: ModalContent) => {},
});

export const ModalProvider: React.FC = ({ children }) => {
  const [currentModal, setCurrentModal] = useState<ModalContent | undefined>(undefined);

  const openModal = (modalContent: ModalContent) => {
    setCurrentModal(modalContent);
  };

  const closeModal = () => {
    setCurrentModal(undefined);
  };

  const isModalOpen = currentModal !== undefined;

  const value = useMemo(() => ({
    isModalOpen,
    currentModal,
    openModal,
    closeModal,
  }), [currentModal, isModalOpen]);

  return (
    <ModalContext.Provider value={value}>
      {children}
    </ModalContext.Provider>
  );
};
