import { call, put } from 'redux-saga/effects';
import { callApi } from 'utils/api';
import { ApiEndpoint } from 'utils/api/constants';
import { simpleSagaErrorHandler } from 'utils/api/simpleSagaErrorHandler';
import { NotificationName, showNotificationMessage } from 'appConstants';
import { authEmail, authLogout } from '../actionCreators';

export function* authEmailSaga({
  payload: { email, password },
  successCallback,
}: ReturnType<typeof authEmail>) {
  try {
    yield put(authLogout());
    yield call(callApi, {
      method: 'POST',
      url: ApiEndpoint.CreateWithEmail,
      data: {
        email,
        password,
        name: '',
        surname: '',
      },
    });

    showNotificationMessage(NotificationName.CheckEmail);
    if (successCallback) successCallback();
  } catch (e) {
    simpleSagaErrorHandler(e);
  }
}
