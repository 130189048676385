import React, {
  FC, useCallback, useContext,
} from 'react';
import { Button, Image, Text } from 'components';
import { ModalContext } from 'context';
import { ModalContent } from 'types';
import cx from 'classnames';
import { emailIcon, logoImage } from 'assets/images';
import { GoogleLoginButtonContainer } from 'containers/GoogleLoginButtonContainer';
import styles from './styles.module.scss';

export const CreateAccountModal: FC = () => {
  const { openModal, closeModal } = useContext(ModalContext);

  const emailHandler = useCallback(() => {
    openModal(ModalContent.RegisterModal);
  }, [openModal]);

  const logInHandler = useCallback(() => {
    openModal(ModalContent.LogInModal);
  }, [openModal]);

  return (
    <div className={styles.wrapper}>
      <Image
        url={logoImage}
        className={styles.logo}
      />
      <Text className={styles.title}>Create a 8.Finance account</Text>
      <Button
        className={cx(styles.button, styles.email)}
        onClick={emailHandler}
      >
        <Image
          className={styles.icon}
          url={emailIcon}
        />
        Create with Email address
      </Button>
      <GoogleLoginButtonContainer
        onLogin={closeModal}
        className={styles.google_button}
      >
        Create with Google account
      </GoogleLoginButtonContainer>
      <div className={styles.label_wrapper}>
        <Text className={styles.label}>Already have an account?</Text>
        <button
          onClick={logInHandler}
          className={cx(styles.log_in, styles.label)}
        >
          Log In
        </button>
      </div>
    </div>
  );
};
