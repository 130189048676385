import { hasPath } from 'ramda';
import assocPath from 'ramda/es/assocPath';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { MiddlewareAPI } from 'redux';
import { ReduxState } from 'store/types';
import { UserAuthDTO } from 'store/auth/types';
import { authLogout, authSetTokens } from 'store/auth/actionCreators';
import { ApiEndpoint } from './constants';
import { api } from '.';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const refreshTokens = async (store: MiddlewareAPI<any, ReduxState>) => {
  const {
    tokens: {
      refresh,
    },
  } = store.getState().auth;
  const res = await api.post<UserAuthDTO>(
    ApiEndpoint.AuthRefresh,
    { refresh_token: refresh },
    { headers: { refresh_token: refresh, session: '' } },
  );
  store.dispatch(authSetTokens(res.data.access_token, res.data.refresh_token));

  return res.data.access_token;
};

/**
 * Handles Bearer authorization and token refresh with store
 * @param store
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useAPIInterceptors = (store: MiddlewareAPI<any, ReduxState>) => {
  // Pass token to axios
  api.interceptors.request.use((options: AxiosRequestConfig): AxiosRequestConfig => {
    const { access, session } = store.getState().auth.tokens;

    if (session && options.headers) {
      // eslint-disable-next-line no-param-reassign
      options.headers.session = session;
    }

    if (
      !access
      || options.url === ApiEndpoint.AuthRefresh
      || hasPath(['headers', 'access_token'], options)
    ) {
      return options;
    }

    return assocPath(['headers', 'access_token'], access, options);
  });

  const onFulfilled = async (res: AxiosResponse) => {
    if (hasPath(['headers', 'session'], res)) {
      const { access, refresh } = store.getState().auth.tokens;
      store.dispatch(authSetTokens(access, refresh, res.headers.session));
    }
    return res;
  };

  // Refresh on 401
  api.interceptors.response.use(onFulfilled, async (error: AxiosError) => {
    const { refresh } = store.getState().auth.tokens;

    if (
      error.response?.status === 401
      && refresh
      && error.config.url !== ApiEndpoint.AuthRefresh
    ) {
      const originalRequest = error.config;

      if (!originalRequest.headers) throw error;

      try {
        // try to refresh token
        originalRequest.headers.access_token = await refreshTokens(store);
        return await api(originalRequest);
      } catch (e) {
        store.dispatch(authLogout());
        // throw original error
        throw error;
      }
    }

    throw error;
  });
};
