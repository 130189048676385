import { call } from 'redux-saga/effects';
import { callApi } from 'utils/api';
import { ApiEndpoint } from 'utils/api/constants';
import { simpleSagaErrorHandler } from 'utils/api/simpleSagaErrorHandler';
import { authVerificationCode } from 'store/auth/actionCreators';
import { NotificationName, showNotificationMessage } from 'appConstants';

export function* authVerificationCodeSaga({
  payload,
  successCallback,
}: ReturnType<typeof authVerificationCode>) {
  try {
    yield call(callApi, {
      method: 'POST',
      url: ApiEndpoint.EmailConfirm,
      data: {
        code: payload,
      },
    });

    showNotificationMessage(NotificationName.EmailConfirmed);
    if (successCallback) successCallback();
  } catch (e) {
    simpleSagaErrorHandler(e);
  }
}
