import { questionIcon } from 'assets/images';
import { Button, ButtonIcon, Text } from 'components';
import { CheckBox } from 'components/CheckBox';
import cx from 'classnames';
import React, { memo, MouseEventHandler } from 'react';
import styles from './styles.module.scss';

interface RegistrationStepProp {
  id: number;
  text: string;
  nameButton: string;
  className: string;
  isQuestion: boolean;
  isCheckBox: boolean;
  checked: boolean;
  disabled: boolean;
  onCheck: (val: boolean) => void;
  onClick?: MouseEventHandler<HTMLButtonElement>
}

export const RegistrationStep = memo<RegistrationStepProp>(
  ({
    id,
    text,
    nameButton,
    isQuestion,
    isCheckBox,
    checked,
    onCheck,
    disabled,
    className,
    onClick,
  }) => {
    const order = (id + 1).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });

    return (
      <div className={cx(styles.step__container, className)}>
        <Text
          className={styles.step__order}
          type="span"
        >
          {order}
        </Text>
        <div className={styles.step__text}>
          <div className={styles.step__text__withQuestion}>
            <Text
              className={styles.step__description}
              type="span"
            >
              {text}
            </Text>
            {isQuestion && (
              <ButtonIcon
                className={styles.step__icon__question}
                imageURL={questionIcon}
              />
            )}
          </div>
          {isCheckBox && (
            <CheckBox
              isDisabled={disabled}
              iconClassName={styles.step__checkbox}
              descriptionClassName={styles.step__checkbox__description}
              isChecked={checked}
              onToggle={onCheck}
              description="Later"
            />
          )}
        </div>
        <div className={styles.step_button_outer}>
          <Button
            onClick={onClick}
            disabled={disabled}
            className={styles.step__button}
          >
            {nameButton}
          </Button>
        </div>
      </div>
    );
  },
);
