import React from 'react';

import {
  CarouselCategoriesContainer,
  RegistrationStepsContainer,
  WelcomContainer,
} from 'containers';

export const Home = () => (
  <>
    <WelcomContainer />
    <CarouselCategoriesContainer />
    <RegistrationStepsContainer />
  </>
);
