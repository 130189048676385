import {
  AuthEmailPasswordPayload,
  AuthEmailPayload,
  AuthLoginReq,
  AuthState,
} from './types';
import { AuthActionType } from './actionTypes';
import { ContractSettings } from '../app/sagas';

export const authReady = () => ({ type: AuthActionType.Ready });

export const authSetState = (payload: Partial<AuthState>) => ({
  type: AuthActionType.SetState,
  payload,
});

export const authSetTokens = (access: string, refresh: string, session?: string) => ({
  type: AuthActionType.SetTokens,
  payload: { access, refresh, session },
});

export const setContractAddresses = (info: ContractSettings) => ({
  type: AuthActionType.SetContractAddresses,
  payload: { info },
});

export const authLogout = (payload?: { reason?: string }) => ({
  type: AuthActionType.Logout,
  payload,
});

export const authRefresh = () => ({ type: AuthActionType.Refresh });

export const authLoginEmail = (payload: AuthEmailPasswordPayload, callBack?: () => void) => ({
  type: AuthActionType.LoginEmail,
  payload,
  callBack,
});

export const authLoginMetamask = () => ({
  type: AuthActionType.LoginMetamask,
});

export const authSignUpEmail = (payload: AuthEmailPasswordPayload, callBack?: () => void) => ({
  type: AuthActionType.SignUpEmail,
  payload,
  callBack,
});

export const authRequestPasswordRecovery = (payload: AuthEmailPayload) => ({
  type: AuthActionType.RequestPasswordRecovery,
  payload,
});

export const authGoogle = (payload: string, successCallback?: () => void) => ({
  type: AuthActionType.GOOGLE_AUTH,
  payload,
  successCallback,
});

export const authEmail = (payload: AuthEmailPasswordPayload, successCallback?: () => void) => ({
  type: AuthActionType.EMAIL_AUTH,
  payload,
  successCallback,
});

export const authVerificationCode = (payload: string, successCallback?: () => void) => ({
  type: AuthActionType.VERIFICATION_CODE,
  payload,
  successCallback,
});

export const authLogin = (payload: AuthLoginReq, successCallback?: () => void) => ({
  type: AuthActionType.LOGIN,
  payload,
  successCallback,
});

/**
 @param payload - email
 @param successCallback - successCallback
 */
export const authPasswordResetRequest = (payload: string, successCallback?: () => void) => ({
  type: AuthActionType.PASSWORD_RESET_REQUEST,
  payload,
  successCallback,
});

/**
 @param payload - confirm code
 @param successCallback - successCallback
 */
export const authPasswordResetConfirm = (payload: string, successCallback?: () => void) => ({
  type: AuthActionType.PASSWORD_RESET_CONFIRM,
  payload,
  successCallback,
});

/**
 @param payload - password
 @param successCallback - successCallback
 */
export const authPasswordReset = (payload: string, successCallback?: () => void) => ({
  type: AuthActionType.PASSWORD_RESET,
  payload,
  successCallback,
});
