import { useMemo, useState } from 'react';
import { testEmailValidation } from 'utils';

export const useAuth = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [verificationCode, setVerificationCode] = useState('');

  const isEmailValidated = useMemo(() => testEmailValidation(email), [email]);

  const currentVerificationCode = useMemo(() => verificationCode.replaceAll(' ', ''), [verificationCode]);

  return {
    email,
    setEmail,
    password,
    setPassword,
    passwordConfirm,
    setPasswordConfirm,
    isEmailValidated,
    setVerificationCode,
    verificationCode: currentVerificationCode,
  };
};
