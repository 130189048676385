import { setNotification } from 'utils/setNotification';
import { ToastifyProps } from 'components/Toastify';

export enum NotificationName {
  PasswordNotMatch = 'PasswordNotMatch',
  CompleteFields = 'CompleteFields',
  EnterCurrentEmail = 'EnterCurrentEmail',
  CheckEmail = 'CheckEmail',
  EmailConfirmed = 'EmailConfirmed',
  FillVerificationCode = 'FillVerificationCode',

  NotSupportNetwork = 'NotSupportNetwork',
  AccountChanged = 'AccountChanged',
  InstallMetamask = 'InstallMetamask',
}

const notificationsContent: Record<NotificationName, ToastifyProps> = {
  [NotificationName.PasswordNotMatch]: { type: 'info', title: 'Warn!', message: 'Passwords do not match' },
  [NotificationName.CompleteFields]: { type: 'info', title: 'Warn!', message: 'Please complete the fields' },
  [NotificationName.EnterCurrentEmail]: { type: 'info', title: 'Warn!', message: 'Please enter the correct email' },
  [NotificationName.FillVerificationCode]: { type: 'info', title: 'Warn!', message: 'Please fill verification code' },
  [NotificationName.CheckEmail]: { type: 'success', title: 'Success!', message: 'Please check your email' },
  [NotificationName.EmailConfirmed]: { type: 'success', title: 'Success!', message: 'Email confirmed' },

  [NotificationName.NotSupportNetwork]: { type: 'error', title: 'Warn!', message: 'The app is not supported on this network' },
  [NotificationName.AccountChanged]: { type: 'success', title: 'Success!', message: 'Your account succesfully changed' },
  [NotificationName.InstallMetamask]: { type: 'error', title: 'Warn!', message: 'Please install the MetaMask extension' },
};

export const showNotificationMessage = (notificationName: NotificationName) => {
  setNotification(notificationsContent[notificationName]);
};
