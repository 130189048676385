import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  LegacyRef,
  MouseEventHandler,
  PropsWithChildren,
} from 'react';

export enum ButtonTheme {
  primary = 'primary',
  secondary = 'secondary',
  outline = 'outline',
  arrow = 'arrow',
  text = 'text',
}

export enum ButtonNames {
  Proceed = 'Proceed to Registration',
  ConnectWallet = 'Connect Wallet',
  LinkTelegram = 'Link Telegram',
  Connect = 'Connect',
}

export type ButtonProps = PropsWithChildren<
DetailedHTMLProps<
ButtonHTMLAttributes<HTMLButtonElement>,
HTMLButtonElement
> & {
  theme?: ButtonTheme;
  isFullWidth?: boolean;
  isSmall?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  iconClassName?: string;
  isLoading?: boolean;
}
>;

export type ButtonRef = LegacyRef<HTMLButtonElement> | undefined;
