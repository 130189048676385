import { ProfileState } from 'store/profile/types';
import { createReducer } from 'utils';
import { profileHandlers } from 'store/profile/handlers';

export const profileInitialState: Readonly<ProfileState> = {
  name: undefined,
  surname: undefined,
  email: undefined,
};

export default createReducer(profileInitialState, profileHandlers);
