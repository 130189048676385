import {
  categoriesMask1, categoriesMask2, participantImage, referalProgramImage,
} from 'assets/images';
import {
  Image, Text, Card, CarouselSlider,
} from 'components';
import { format } from 'date-fns';
import React, { FC } from 'react';
import { SwiperSlide } from 'swiper/react';
import styles from './styles.module.scss';

export type ReferralProgram = {
  text: string;
  icon: string;
  backgroundImage?: string
};

export type News = {
  text: string;
  date: string;
};

export const referralPrograms: ReferralProgram[] = [
  {
    text: 'What is a referral program?',
    icon: referalProgramImage,
    backgroundImage: categoriesMask1,
  },
  {
    text: 'How to become a participant?',
    icon: participantImage,
    backgroundImage: categoriesMask2,
  },
  {
    text: 'How to become a participantttt?',
    icon: participantImage,
    backgroundImage: categoriesMask1,
  },
  {
    text: 'How to become ?',
    icon: participantImage,
    backgroundImage: categoriesMask2,
  },
  {
    text: 'How to become a participantt?',
    icon: participantImage,
    backgroundImage: categoriesMask1,
  },
];

export const ourNews: News[] = [
  {
    text: 'Token presale starts soon!',
    date: format(new Date(), 'dd/MM/yyyy HH:mm'),
  },
  {
    text: 'Subscribe to our twitter!',
    date: format(new Date(), 'dd/MM/yyyy HH:mm'),
  },
];

export const CarouselCategoriesContainer: FC = () => (
  <section className={styles.carousels_outer_container}>
    <div className={styles.carousel__container__left}>
      <Text
        className={styles.carousel__head}
        type="h3"
      >
        Referral program.
      </Text>
      <CarouselSlider>
        {referralPrograms.map((program) => (
          <SwiperSlide
            className={styles.carousels_card}
            key={program.text}
          >
            <Card backgroundImage={program.backgroundImage}>
              <Image url={program.icon} />
              <Text
                className={styles.carousels__text}
                type="span"
              >
                {program.text}
              </Text>
            </Card>
          </SwiperSlide>
        ))}
      </CarouselSlider>
    </div>
    <div className={styles.carousel__container__right}>
      <Text
        className={styles.carousel__head}
        type="h3"
      >
        Our news
      </Text>
      <CarouselSlider>
        {ourNews.map((news) => (
          <SwiperSlide
            className={styles.carousels_card_news}
            key={news.text}
          >
            <Card>
              <Text
                className={styles.carousels__text}
                type="span"
              >
                {news.text}
              </Text>
              <Text
                className={styles.carousels__date}
                type="span"
              >
                {news.date}
              </Text>
            </Card>
          </SwiperSlide>
        ))}
      </CarouselSlider>
    </div>
  </section>
);
