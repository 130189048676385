import { Button, Text } from 'components';
import { ButtonNames, ButtonTheme } from 'components/Button/types';
import { RegistrationStep } from 'containers';
import React, {
  FC, MouseEventHandler, useCallback, useContext, useMemo, useState,
} from 'react';
import { ModalContext } from 'context';
import { ModalContent, WalletStatus } from 'types';
import { useShallowSelector, useUser } from 'hooks';
import { walletSelectors } from 'store/wallet/selectors';
import { useDispatch } from 'react-redux';
import { walletConnect } from 'store/wallet/actionCreators';
import { pointsText } from './pointsContent';
import styles from './styles.module.scss';

type PointContent = {
  text: string,
  nameButton: ButtonNames,
  isQuestion: boolean,
  isCheckBox: boolean,
  disabled: boolean,
  onClick?: MouseEventHandler<HTMLButtonElement>
  onCheck: (value: boolean) => void
};

type Points = {
  register: PointContent
  connectWallet: PointContent
  linkTelegram: PointContent
  connectTelegram: PointContent
};

type PointKeys = keyof Points;

export const RegistrationStepsContainer: FC = () => {
  const dispatch = useDispatch();
  const walletStatus = useShallowSelector(walletSelectors.getProp('status'));
  const { isUserAuthenticated } = useUser();
  const { openModal } = useContext(ModalContext);

  const [checkedPointKeys, setCheckedPointKeys] = useState<PointKeys[]>([]);

  const registerHandler = useCallback(() => {
    openModal(ModalContent.CreateAccount);
  }, [openModal]);

  const connectWalletHandler = useCallback(() => {
    dispatch(walletConnect());
  }, [dispatch]);

  const onCheckHandler = useCallback((key: PointKeys) => (value: boolean) => {
    if (value) {
      setCheckedPointKeys(((prevState) => [...prevState, key]));
    } else {
      const filteredPoints = checkedPointKeys.filter((point) => point !== key);
      setCheckedPointKeys(filteredPoints);
    }
  }, [checkedPointKeys]);

  const pointsContent = useMemo<Points>(() => ({
    register: {
      text: pointsText.register,
      nameButton: ButtonNames.Proceed,
      isQuestion: false,
      isCheckBox: false,
      onClick: registerHandler,
      disabled: isUserAuthenticated,
      onCheck: onCheckHandler('register'),
    },
    connectWallet: {
      text: pointsText.connectWallet,
      nameButton: ButtonNames.ConnectWallet,
      isQuestion: true,
      isCheckBox: true,
      disabled: walletStatus === WalletStatus.CONNECTED,
      onClick: connectWalletHandler,
      onCheck: onCheckHandler('connectWallet'),
    },
    linkTelegram: {
      text: pointsText.linkTelegram,
      nameButton: ButtonNames.LinkTelegram,
      isQuestion: true,
      isCheckBox: true,
      disabled: !isUserAuthenticated,
      onCheck: onCheckHandler('linkTelegram'),
    },
    connectTelegram: {
      text: pointsText.connectTelegram,
      nameButton: ButtonNames.Connect,
      isQuestion: true,
      isCheckBox: true,
      disabled: !isUserAuthenticated,
      onCheck: onCheckHandler('connectTelegram'),
    },
  }), [registerHandler, isUserAuthenticated, walletStatus, connectWalletHandler, onCheckHandler]);

  return (
    <section className={styles.steps_outer_container}>
      <Text
        className={styles.step__head__h2}
        type="h2"
      >
        Pass the survey & get a reward
      </Text>
      <Text
        className={styles.step__head__h3}
        type="h3"
      >
        MetaFi made easy
      </Text>
      <div className={styles.steps__container}>
        {Object.keys(pointsContent).map(
          (key, index) => {
            const {
              text, nameButton, isQuestion, isCheckBox, onClick, disabled, onCheck,
            } = pointsContent[key as PointKeys];
            return (
              <RegistrationStep
                key={nameButton}
                className={styles.step_outer_container}
                id={index}
                text={text}
                nameButton={nameButton}
                isQuestion={isQuestion}
                isCheckBox={isCheckBox}
                onCheck={onCheck}
                checked={checkedPointKeys.includes(key as PointKeys)}
                disabled={disabled}
                onClick={onClick}
              />
            );
          },
        )}
      </div>
      <div className={styles.step__buttons}>
        <Button
          theme={ButtonTheme.secondary}
          className={styles.step__submit}
        >
          Submit
        </Button>
        <Button
          theme={ButtonTheme.outline}
          className={styles.step__cancel}
        >
          Cancel
        </Button>
      </div>
    </section>
  );
};
