import { toast } from 'react-toastify';
import { transformBackendErrorToString } from './transformBackendErrorToString';

export const sagaExceptionHandler = (exception: unknown) => {
  let message = '';
  if (exception instanceof Error) {
    message = transformBackendErrorToString(exception);
  }

  if (typeof exception === 'string') {
    message = exception;
  }

  toast.error(message);
};
