import React, { forwardRef, memo } from 'react';
import cx from 'classnames';
import { useHoverEvent } from 'hooks';
import { Loader } from '../Loader';
import {
  ButtonProps,
  ButtonRef,
  ButtonTheme,
} from './types';
import styles from './styles.module.scss';

const Button = memo(forwardRef((
  {
    theme = ButtonTheme.primary,
    isFullWidth,
    isSmall,
    onClick,
    className,
    children,
    disabled,
    isLoading,
  }: ButtonProps,
  ref: ButtonRef,
) => {
  const { onMouseEnter, onMouseLeave } = useHoverEvent();

  return (
    <button
      ref={ref}
      type="button"
      className={cx(
        styles.button,
        styles[theme],
        {
          [styles.small]: isSmall,
          [styles.full_width]: isFullWidth,
        },
        className,
      )}
      onClick={onClick}
      disabled={disabled || isLoading}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {isLoading ? <Loader /> : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {children}
        </>
      )}
    </button>
  );
}));

export { Button };
