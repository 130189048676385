import { authPasswordResetRequest } from 'store/auth/actionCreators';
import { call } from 'redux-saga/effects';
import { callApi } from 'utils/api';
import { ApiEndpoint } from 'utils/api/constants';
import { simpleSagaErrorHandler } from 'utils/api/simpleSagaErrorHandler';
import { NotificationName, showNotificationMessage } from 'appConstants';

export function* authPasswordResetRequestSaga({
  payload,
  successCallback,
}: ReturnType<typeof authPasswordResetRequest>) {
  try {
    yield call(callApi, {
      url: ApiEndpoint.PasswordResetRequest,
      method: 'POST',
      data: {
        email: payload,
      },
    });

    if (successCallback) successCallback();
    showNotificationMessage(NotificationName.CheckEmail);
  } catch (e) {
    simpleSagaErrorHandler(e);
  }
}
