import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

export const configureApi = (baseURL?: string) => axios.create({ baseURL });
export const api = configureApi(`${process.env.REACT_APP_API_URL}/api`);

export const adminApi = configureApi(`${process.env.REACT_APP_API_URL}/api`);

export function* callApi(
  requestConfig: AxiosRequestConfig,
): SagaIterator {
  const res: AxiosResponse = yield call<(config: AxiosRequestConfig) => void>(api, requestConfig);

  return res.data;
}
