/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useCallback } from 'react';
import { GoogleLogin, GoogleLoginProps, GoogleLoginResponse } from 'react-google-login';
import { googleId } from 'appConstants';
import { Button, Image } from 'components';
import cx from 'classnames';
import { googleIcon } from 'assets/images';
import { authGoogle } from 'store/auth/actionCreators';
import { useDispatch } from 'react-redux';
import styles from './styles.module.scss';

function isGoogleLoginResponse(object: any): object is GoogleLoginResponse {
  return 'googleId' in object;
}

type Props = {
  className?: string
  onLogin?: () => void
};

export const GoogleLoginButtonContainer: FC<Props> = ({
  className,
  onLogin,
  children,
}) => {
  const dispatch = useDispatch();

  const googleSuccessHandler = useCallback<Required<GoogleLoginProps>['onSuccess']>((res) => {
    if (isGoogleLoginResponse(res)) {
      dispatch(authGoogle(res.tokenId, onLogin));
    }
  }, [dispatch, onLogin]);

  const googleErrorHandler = useCallback(() => {

  }, []);

  const googleRenderHandler = useCallback<Required<GoogleLoginProps>['render']>((props) => (
    <Button
      className={cx(styles.button, className)}
      {...props}
    >
      <Image
        className={styles.icon}
        url={googleIcon}
      />
      {children}
    </Button>
  ), [className, children]);

  return (
    <GoogleLogin
      clientId={googleId}
      buttonText="Login"
      onSuccess={googleSuccessHandler}
      onFailure={googleErrorHandler}
      cookiePolicy="single_host_origin"
      render={googleRenderHandler}
    />
  );
};
